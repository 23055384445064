@tailwind utilities;

@layer utilities {
    .btn-clickable {
        @apply shadow-md shadow-slate-300 transition-all duration-200 transform cursor-pointer;
    }
    .btn-clickable:hover {
        @apply shadow-lg shadow-slate-300 scale-[1.10];
    }
}


/* .btn-clickable {
    cursor: pointer;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
    position: relative;
}

.btn-clickable::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    border-radius: inherit;
}

.btn-clickable:hover {
    transform: scale(1.02, 1.02);
}

.btn-clickable:hover::after {
    opacity: 1;
}

a.btn-clickable {
    color: inherit;
    text-decoration: inherit;
}

button {
    cursor: pointer;
    padding: 10px 15px;
    border: none;
} */