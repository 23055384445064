footer nav {
    display: flex;
    justify-content: start;
    align-items: end;
    width: 100%;
    max-width: 1200px;
    margin: 0px 20px;
}

footer nav ul {
    display: flex;
    flex-direction: column;
    justify-content: end;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

footer nav a,
footer nav p {
    color: black;
    text-decoration: none;
    font-size: small;
}

footer .dash-footer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 30px;
}

footer .row .middle {
    padding: 0px 10px;
    margin: 0px 10px;
}

footer .row *,
footer .row-sectioned * {
    color: darkgrey;
}

footer .row,
footer .row-sectioned {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-top: 12px;
}

footer .row-sectioned .middle {
    padding: 0px 10px;
    margin: 0px 10px;
    border-right: 1px solid darkgrey;
    border-left: 1px solid darkgrey;
}

footer {
    background-color: white;
    height: 80px;
    padding: 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    overflow: hidden;
    width: 100%;
    position: fixed;
    bottom: 0;
}