.report-container-new p, .report-container-new span, .report-container-new li { 
    font-family: 'Arial', sans-serif; 
}

.report-container-new .section.intro, .report-container-new .section.intro p, .report-container-new .section.intro b, .report-container-new .section.intro i {
    font-family: inherit;
}

.report-container-new li b, .report-container-new li span, .report-container-new li i {
    font-family: inherit !important;
}

.report-container-new .section { 
    padding: 15px; 
    max-width: 1350px;
    margin: 15px auto;
    background: white; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
    border-radius: 10px;
    border: 1px solid rgba(200, 200, 200, 0.7); 
}

.report-container-new .section h2 { 
    font-size: larger;
}

.report-container-new li {
    font-size: medium;
}

.report-container-new summary {
    margin: 0px;
    padding: 5px; 
    list-style-type: none;
    cursor: pointer;
}

.report-container-new .news-day {
    border-radius: 10px;
    border: 1px solid black;
    padding: 10px;
    margin: 5px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.report-container-new details[open] > .news-day {
    border: 1.5px solid black;
    background-color: var(--zanista-orange-mid);
}

.report-container-new .news-day:hover {
    background-color: var(--zanista-orange-light);
}

.report-container-new details[open] > .news-list {
    border: 1.5px solid black;
}

.report-container-new details[open] > .news-list.positive {
    border: 1.5px solid green;
}

.report-container-new details[open] > .news-list.negative {
    border: 1.5px solid red;
}

.report-container-new .news-list-container {
    padding: 10px 10px;
    scrollbar-width: thin;
    scrollbar-color: orange transparent;
    overflow-y: auto;
    max-height: 200px;
    margin-top: 5px;
}

/* .report-container-new .news-list-container.expanded {
    max-height: 600px;
}

.report-container-new .news-list-container.collapsed {
    max-height: 200px;
} */

.report-container-new .news-list-container:has(details[open]) {
    max-height: 600px !important;
}

.report-container-new .news-list {
    border-radius: 10px;
    border: 1px solid black;
    padding: 10px;
    margin: 5px 0px 5px 5px;
}

.report-container-new .chart-section {
    width: 100%;
    height: 30rem;
}

.report-container-new .chart-section h2 {
    margin-left: 5px;
}

.report-container-new .positive {
    color: green;
}

.report-container-new .negative {
    color: red;
}

.report-container-new .neutral {
    color: gray;
}

.report-container-new a { 
    color: #007BFF; 
    text-decoration: none;
    font-size: inherit;
}

.report-container-new a:hover { 
    text-decoration: underline; 
}

.report-container-new .news-item {
    border-radius: 10px;
    border: 1px solid black;
    padding: 10px;
    margin: 5px 0px 5px 10px;
}

.report-container-new .duplicate-news {
    border: 1px solid red;
}

.report-container-new .intro p, .report-container-new .intro b, .report-container-new .intro i {
    font-size: 12px;
}

.report-container-new .intro p, .report-container-new .intro i {
    padding-bottom: 5px;
}

.report-container-new .intro {
    padding-bottom: 5px;
}

/* Remove triangle icon from details */
summary, details, details[open] summary {
    list-style: none;
}

summary::-webkit-details-marker, details::-webkit-details-marker,m details[open] summary::-webkit-details-marker {
    display: none;
}
