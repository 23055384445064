@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, 'afacad-flux-normal', 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@layer base {
    html {
        @apply text-slate-900;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: "Afacad Flux", sans-serif;
        font-optical-sizing: auto;
        font-weight: 500;
        font-size: 18px;
        font-variation-settings: "slnt" 0;
    }
}