.editable-exam-name {
    margin: 0px;
    margin-top: 17px;
    margin-bottom: 27px;
}

.editable-exam-name-span {
    overflow: hidden;
    line-height: 1em;
    font-size: var(--font-size-h1);
    outline-offset: 5px;
    outline: 2px solid;
    outline-color: transparent;
    border-radius: 2px;
}

.editable-exam-name-span:not([contenteditable="false"]):hover {
    outline-color: var(--zanista-orange-dark);
    cursor: pointer;
}

.editable-exam-name-span:focus {
    outline-color: black;
    outline: 2px solid;
}