.question-feedback-stats {
    margin-right: 10px;
    margin-top: 10px;
}

.question-feedback-stats>* {
    margin-bottom: 5px;
}

.question-stat-content {
    padding-left: 20px;
}

.student-change-button {
    padding: 0px 7px;
    width: min-content;
    text-wrap: nowrap;
}