.section-heading {
    justify-content: space-between;
    align-items: center;
}

.section-heading-left {
    align-items: baseline;
    cursor: pointer;
    width: 100%;
}

.section-heading-text {
    font-style: italic;
    font-size: 20px;
}

.structure-section-content {
    overflow-x: auto;
    overflow-y: hidden;
}