.rubric-search {
    /* border: 1px solid rgb(77, 77, 77); */
    padding-left: 10px;
    border-radius: 10px;
}

.rubric-search:focus-within {
    border: none;
    outline: 2px solid black;
}

.rubric-search:focus-within::placeholder {
    color: black;
}

.rubric-search-input {
    border: none;
    outline: none;
}

.rubric-search-input:focus-within {
    border: none;
    outline: none;
}

.rubric-search-clear:hover * {
    color: var(--zanista-orange-dark);
}