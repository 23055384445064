:root {
    --font-weight-semibold: 600;
    --font-size-h1: 64px;
}

/* !!! LEGACY -> PREFER TAILWIND */

h1 {
    font-size: var(--font-size-h1);
    border: 1px solid transparent;
}

.font-size-64px {
    font-size: 64px;
}

.font-size-x-large {
    font-size: x-large;
}

.font-size-large {
    font-size: 20px;
}

.font-size-medium {
    font-size: medium;
}

.font-size-small {
    font-size: small;
}

.font-weight-bold {
    font-weight: bold;
}

.font-weight-semibold {
    font-weight: var(--font-weight-semibold);
}

.font-italic {
    font-style: italic;
}

.text-nowrap {
    text-wrap: nowrap;
}

.word-wrap-break-word {
    word-wrap: break-word;
}

.word-wrap-normal {
    word-wrap: normal;
}

.word-wrap-break-all {
    word-wrap: break-all;
}

.text-align-center {
    text-align: center;
}
