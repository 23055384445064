.back-link a {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    text-decoration: none;
    padding: 5px 0px;
    color: var(--zanista-orange);
}

.back-link p {
    margin: 0px;
    padding: 5px 5px;
    font-size: small;
    font-weight: bold;
}

@media only screen and (min-width: 768px) {

    .back-link p {
        font-size: medium;
    }

}