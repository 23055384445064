.exam-content {
    margin-bottom: 80px;
}

.exam-details {
    padding-bottom: 100px;
}

body {
    overflow-y: scroll;
}