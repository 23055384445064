.grade-grid {
    border-collapse: separate;
    border-spacing: 5px;
    padding: 5px 5px 10px 35px;
    max-width: 100%;
}

.grade-grid:focus-within {
    border: 1px solid gray;
}

.grade-grid td {
    border: none;
    border-radius: 5px;
    padding: 0;
}

.input-cell {
    outline: 1px var(--zanista-grey-mid) solid;
    outline-offset: -1px;
    padding: 10px 15px !important;
}

.input-cell input {
    background-color: transparent;
    /* width: 125px; */
}

.input-cell:hover {
    outline: 1px gray solid;
}

.input-cell:focus-within {
    outline: 2px black solid;
    outline-offset: -2px;
}

.mark-input {
    text-align: end;
}

.add-grade {
    box-sizing: border-box;
    border-radius: 5px;
    border: none;
    outline: 1px var(--zanista-grey-mid) solid;
    outline-offset: -1px;
    padding: 10px;
}

.add-grade:hover {
    border: none;
    outline: 1px gray solid;
    background-color: var(--zanista-yellow-light);
}

.add-grade:focus {
    border: none;
    outline: 1px gray solid !important;
}

.delete-grade-row {
    opacity: 0;
    height: min-content;
    vertical-align: middle;
    padding-bottom: 2px;
    /* margin: 0px 7px; */
}

.boundary-row:hover .delete-grade-row {
    opacity: 1;
}

.delete-grade-row:hover {
    color: var(--zanista-orange-dark);
}

.grade-boundary-presets {
    padding-top: 21px;
}