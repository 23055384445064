.report-container { 
    font-family: 'Arial', sans-serif; 
    margin: 0; 
    padding: 0; 
}

.report-container .header {
    background: linear-gradient(to right, #FFA500, #FFA500);
    padding: 10px 20px;
    color: white;
}
.report-container .header h1 {
    margin: 0;
    font-size: large;
}

.report-container .section { 
    padding: 15px 15px; 
    max-width: 1350px;
    margin: 20px auto; 
    background: white; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    border-radius: 10px;
}

@media only screen and (min-width: 768px) {
    .report-container .section { 
        padding: 20px 40px; 
    }
}

.report-container .section h2 { 
    border-bottom: 2px solid #FFA500;
    padding-bottom: 10px;
    font-size: larger;
    color: #FFA500;
}
.report-container .section p { 
    font-size: medium; 
    color: #444; 
    line-height: 1.6; 
}
.report-container li { 
    margin-bottom: 10px;
    font-size: medium;
}
.report-container details > summary {
    font-size: medium;
    font-weight: bold;
    cursor: pointer;
    padding: 10px;
    background-color: #e9e9e9;
    border: 1px solid #ddd;
    border-radius: 5px;
    list-style-position: inside;
}
.report-container details[open] > summary, .report-container details[open] > summary > div {
    background-color: #DDD;
}

.report-container details > summary > div {
    font-size: medium;
    font-weight: bold;
}

.report-container ul {
    padding-left: 20px;
    list-style-type: disc;
    font-size: inherit;
}
.report-container ul li {
    margin-bottom: 5px;
}
.report-container .positive {
    color: green;
}
.report-container .negative {
    color: red;
}
.report-container .neutral {
    color: gray;
}
.report-container a { 
    color: #007BFF; 
    text-decoration: none;
    font-size: inherit;
}
.report-container a:hover { 
    text-decoration: underline; 
}

/* Only show 2 columns on mobile */
.report-container th:nth-child(n+3), .report-container td:nth-child(n+3) {
    display: none;
}

@media only screen and (min-width: 992px) {
    .report-container th:nth-child(n+3), .report-container td:nth-child(n+3) {
        display: table-cell;
    }
}

.report-container table { 
    width: 100%; 
    border-collapse: collapse; 
    margin-top: 20px; 
}
.report-container table, .report-container th, .report-container td { 
    border: 1px solid #ddd; 
}
.report-container th, .report-container td { 
    padding: 8px; 
    text-align: left; 
    font-size: medium;
}
.report-container th { 
    background-color: #f2f2f2; 
}
.report-container .logo {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 100px;
}
.report-container .intro {
    padding: 15px 15px; 
    background: #FFF8DC; 
    margin: 20px auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media only screen and (min-width: 768px) {
    .report-container .intro {
        padding: 20px 40px; 
    }
}

.report-container .intro p {
    font-size: small;
    color: #444;
}

.report-container .content {
    padding-bottom: 10px;
}
.report-container .title-page {
    text-align: center;
    margin: 30px 0;
}
.report-container .title-page h1 {
    font-size: xx-large;
    color: #FFA500;
}
.report-container .title-page h2 {
    font-size: large;
    color: #666;
}
.report-container .plotly-graph-div {
    margin: 0 !important;
    padding: 0 !important;
    height: auto !important; /* Allow dynamic height */
    min-height: 600px; /* Ensure minimum height to avoid compression */
    overflow: hidden; /* Ensure no overflow adds extra space */
}
.report-container .logo {
    display: none;
}

/* For now, we will hide the modebar */
.plotly .modebar-container {
    display: none !important;
}

.plotly .svg-container .hoverlayer .hovertext .nums tspan {
    font-size: 14px !important;
}

/* Hide the PPPs on small screens */
.report-container .ppp {
    display: none;
}

.js-plotly-plot .xtick text, .js-plotly-plot .xtick tspan {
    font-size: 12px !important;
}

.custom-tooltip {
    font-size: 14px !important;
}

/* select the first br element and hide it */
.custom-tooltip br:first-of-type {
    display: none !important;
}

@media only screen and (min-width: 768px) {
    /* Show the PPPs on large screens */
    .report-container .ppp {
        display: block;
        min-height: 360px;
    }
}

.report-container .description .description-title { 
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.report-container .description .description-title h2 { 
    width: 90%;
}

.report-container .description .description-title button { 
    font-size: medium;
    color: #ffa200;
    padding: 0px 10px;
    cursor: pointer;
}

.report-container .description.folded p, .report-container .description.folded ul { 
    display: none;
}

.report-container .description p, .report-container .description ul { 
    display: block;
}


.loading-placeholder {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 20px 0;
}

.loading-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
}

.loading-header {
    width: 70%;
    height: 20px;
    border-radius: 10px;
    background-color: #e6e6e6;
    margin-bottom: 10px;
}

.loading-text-line {
    width: 100%;
    height: 12px;
    border-radius: 6px;
    background-color: #e6e6e6;
}

.loading-text-line.short {
    width: 50%;
}

/* Draw a table with loading placeholders */
.loading-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 0;
}

.loading-table th, .loading-table td {
    padding: 10px;
    border-bottom: 1px solid #e6e6e6;
}

.loading-table td {
    height: 30px;
}

.loading-header,
.loading-text-line,
.loading-text-line.short {
    animation: flash 1.5s infinite;
}

@keyframes flash {
    0% {
        background-color: #e6e6e6;
    }
    50% {
        background-color: #f0f0f0;
    }
    100% {
        background-color: #e6e6e6;
    }
}

.loading-text-line:nth-child(3) {
    animation-delay: 0.3s;
}

.loading-text-line:nth-child(4) {
    animation-delay: 0.4s;
}

.report-container .section.sign-up-card {
    height: 300px; 
    display: flex; 
    justify-content: center; 
    align-items: center;
}

.g-ytitle, .g-y2title, .infolayer {
    display: none;
}

@media only screen and (min-width: 768px) {
    .g-ytitle, .g-y2title, .infolayer {
        display: block;
    }

}