:root {
    --md-breakpoint: 768px;
    --navbar-height: 80px;

    --margin-xx-large: 30px;
    --margin-x-large: 20px;
    --margin-large: 15px;
    --margin-mid: 10px;
    --margin-small: 5px;
    --margin-x-small: 2px;

    --padding-x-large: 20px;
    --padding-large: 15px;
    --padding-mid: 10px;
    --padding-small: 5px;
    --padding-x-small: 2px;

    --gap-x-large: 30px;
    --gap-large: 20px;
    --gap-mid: 10px;
    --gap-small: 5px;
}

/* !!! LEGACY -> PREFER TAILWIND */

.position-relative {
    position: relative;
}

.fill-width {
    width: 100%;
}

.fill-height {
    height: 100%;
}

.centered {
    display: flex;
    justify-content: center;
}

.container {
    max-width: 1200px;
    width: 100%;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.align-center {
    align-items: center;
}

.align-baseline {
    align-items: baseline;
}

.align-end {
    align-items: end;
}

.justify-self-left {
    justify-self: left;
}

.justify-self-right {
    justify-self: right;
}

.justify-content-left {
    justify-content: left;
}

.justify-content-right {
    justify-content: right;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-space-between {
    justify-content: space-between;
}

.justify-content-evenly {
    justify-content: space-evenly;
}

.justify-items-left {
    justify-items: left;
}

.justify-items-right {
    justify-items: right;
}

.vertical-align-middle {
    vertical-align: middle;
}

.vertical-align-bottom {
    vertical-align: bottom;
}

.flex-row {
    display: flex;
    flex-direction: row;
    position: relative;
}

.flex-col {
    display: flex;
    flex-direction: column;
    position: relative;
}

.display-block {
    display: block;
}

.display-inline-flex {
    display: inline-flex;
}

.display-inline-block {
    display: inline-block;
}

.box-sizing-border-box {
    box-sizing: border-box;
}

.margin-auto {
    margin: auto;
}

.margin-x-large {
    margin: var(--margin-x-large);
}

.margin-large {
    margin: var(--margin-large);
}

.margin-mid {
    margin: var(--margin-mid);
}

.margin-small {
    margin: var(--margin-small);
}

.margin-none {
    margin: 0;
}

.padding-large {
    padding: var(--padding-large);
}

.padding-mid {
    padding: var(--padding-mid);
}

.padding-small {
    padding: var(--padding-small);
}

.padding-x-small {
    padding: var(--padding-x-small);
}

.padding-none {
    padding: 0;
}

.gap-x-large {
    gap: var(--gap-x-large);
}

.gap-large {
    gap: var(--gap-large);
}

.gap-mid {
    gap: var(--gap-mid);
}

.gap-small {
    gap: var(--gap-small);
}