:root {
    /* Border */
    /* --border-thin: 1px;
    --border-mid: 2px; */

    --rounded-xsmall: 5px;
    --rounded-small: 10px;
    --rounded-mid: 15px;
    --rounded-big: 20px;
}

/* !!! LEGACY -> PREFER TAILWIND */

/* Colour */
.bg-white {
    background-color: white
}

.bg-grey-light {
    background-color: var(--zanista-grey-light);
}

.bg-yellow-light {
    background-color: var(--zanista-yellow-light);
}

.bg-orange-light {
    background-color: var(--zanista-orange-light);
}

.bg-orange-mid {
    background-color: var(--zanista-orange-mid);
}

.bg-orange {
    background-color: var(--zanista-orange);
}

.bg-orange-dark {
    background-color: var(--zanista-orange-dark);
}

.bg-red-light {
    background-color: var(--zanista-red-light);
}

.bg-red-mid {
    background-color: var(--zanista-red-mid);
}

.bg-red {
    background-color: var(--zanista-red);
}

.fg-black {
    color: black;
}

.fg-grey {
    color: gray;
}

.fg-orange-dark {
    color: var(--zanista-orange-dark);
}

/* Border and Outline */
.border-black {
    border-color: black;
}

.border-grey {
    border-color: gray;
}

.border-grey-mid {
    border-color: var(--zanista-grey-mid);
}

.border-grey-light {
    border-color: var(--zanista-grey-light);
}

.border-orange-dark {
    border-color: var(--zanista-orange-dark);
}

.border-transparent {
    border-color: transparent;
}

.border-none {
    border: none;
}

.border-thin {
    border: var(--border-thin) solid;
}

.border-mid {
    border: var(--border-mid) solid;
}

.outline-black {
    outline-color: black;
}

.outline-grey {
    outline-color: gray;
}

.outline-orange-dark {
    outline-color: var(--zanista-orange-dark);
}

.outline-orange-light {
    outline-color: var(--zanista-orange-light);
}

.outline-transparent {
    outline-color: transparent;
}

.outline-none {
    outline: none;
}

.outline-thin {
    outline-style: solid;
    outline-width: var(--border-thin);
}

.outline-mid {
    outline-style: solid;
    outline-width: var(--border-mid);
}

.rounded-xsmall {
    border-radius: var(--rounded-xsmall);
}

.rounded-small {
    border-radius: var(--rounded-small);
}

.rounded-mid {
    border-radius: var(--rounded-mid);
}

.rounded-big {
    border-radius: var(--rounded-big);
}

.translucent {
    opacity: 0.5;
}