.afacad-flux-normal {
    font-family: "Afacad Flux", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-variation-settings:
        "slnt" 0;
}

.about-flex-col {
    flex-direction: column;
}

@media screen and (min-width: 640px) {
    .about-flex-col {
        flex-direction: row;
    }
}

.hero-section {
    height: 100%;
    /* Adjust height to account for the navbar */
    margin-bottom: 80px;
}

@media screen and (min-width: 768px) {
    .hero-section {
        height: calc(850px - var(--navbar-height));
        /* Adjust height to account for the navbar */
        margin-bottom: inherit;
    }
}

body.landing-page-active {
    padding: 0;
    /* Remove padding when landing page is active */
    padding-top: var(--navbar-height);
    overflow-x: hidden;
    background-color: #f6f9fc;
}

body.home-page-active {
    overflow-x: hidden;
    background-color: #f6f9fc;
}

.hero-section {
    /* background: #ff7f44 /* linear-gradient(0deg, #EF753D, var(--zanista-orange-dark));*/
    text-align: center;
    overflow: visible;
    position: relative;
}

.hero-section::before {
    content: "";
    position: absolute;
    top: -50px;
    left: -50px;
    width: 200px;
    height: 200px;
    background: rgba(255, 202, 170, 0.115);
    border-radius: 50%;
    z-index: 1;
    animation: float 6s ease-in-out infinite;
}

.hero-section::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    background: linear-gradient(358deg, transparent 50%, #ff89229c 60%);
    width: 100%;
    height: 200%;
    z-index: -1;
}

@keyframes float {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(20px);
    }
}