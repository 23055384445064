.answers-item-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.answers-item-question-tag {
    grid-column: 1;
}

.answers-item-parts {
    grid-column: 2;
}