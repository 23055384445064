.form-item {
    display: grid;
    grid-template-columns: 20% auto;
    margin: 20px 0px;
}

.form-item-content {
    padding: 10px 14px;
    border: 1px solid var(--zanista-grey-mid);
    border-radius: 4px;
    width: 100%;
}

.form-item-content:hover {
    border: 1px solid gray;
}

.form-item-content::placeholder {
    color: var(--zanista-grey-mid);
    font-weight: 100;
    font-style: italic;
}

select.form-item-content {
    padding: 10px 9px;
    cursor: pointer;
}

.MuiPickersPopper-root {
    background-color: transparent;
}

.MuiPickersLayout-contentWrapper {
    border: 1px solid black;
    border-radius: 5px;
}

.MuiPickersDay-root.Mui-selected {
    border: 1px solid black;
}

.MuiPickersDay-root.MuiPickersDay-today {
    background-color: var(--zanista-yellow-light);
}