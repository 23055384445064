* {
    box-sizing: border-box;
}

body {
    background-color: white;
    padding-top: 5rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-bottom: 5rem;

}

.display-none {
    display: none;
}

ul {
    list-style-position: inside;
}

.cursor-disabled {
    cursor: default;
    pointer-events: none;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-text {
    cursor: text;
}

.pointer-events-disabled {
    pointer-events: none;
}

.user-select-none {
    user-select: none;
}

span:empty:before {
    content: "\200b";
    /* unicode zero width space character */
}

.popup-overlay {
    background: rgba(0, 0, 0, 0.5);
}

/* animation */
@keyframes anvil {
    0% {
        transform: scale(1) translateY(0px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }

    1% {
        transform: scale(0.96) translateY(10px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }

    100% {
        transform: scale(1) translateY(0px);
        opacity: 1;
        box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
}

.popup-animation-slow {
    -webkit-animation: anvil 0.1s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    animation: anvil 0.1s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

.popup-animation-fast {
    -webkit-animation: anvil 0.05s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    animation: anvil 0.05s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}