.product-hero::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    /* Extend this pseudo-element beyond the container */
    background: linear-gradient(345deg, transparent 40%, #ff89229c 60%);
    z-index: -1;
    /* Place pseudo-element below container content */
}