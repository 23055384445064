#main-dashboard {
    margin-top: 44px;
}

.product-panel {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    column-gap: 30px;
    row-gap: 30px;
}

.product-card {
    display: flex;
    padding: 30px;
    box-sizing: border-box;
}

.product-name {
    margin-top: var(--margin-mid);
}



@media (max-width: 1024px) {
    .product-panel {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {
    .product-panel {
        grid-template-columns: repeat(1, 1fr);
    }
}