.katex-html {
    display: none;
}

.katex-mathml {
    overflow-x: scroll;
}

.clear-hidden svg {
    visibility: hidden;
}

.clear-hidden:hover p {
    text-decoration: underline;
}

.clear-hidden:hover svg {
    visibility: visible;
}