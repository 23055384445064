.rpv-core__inner-pages {
    scrollbar-width: auto;
    scrollbar-color: var(--zanista-red);
}

.rpv-core__viewer {
    border: none;
}

.rpv-core__viewer--light {
    border: none;
}

.rpv-default-layout__container {
    border: none;
}

.rpv-open__input-wrapper {
    cursor: none;
    display: none;
    margin: -20px;
}

.rpv-default-layout__sidebar-tabs {
    display: none;
}

.rpv-core__inner-page-container {
    border: none;
}

.rpv-core__inner-pages {
    border: none;
}

.rpv-core__inner-pages--vertical {
    border: none;
}

.rpv-core__page-layer {
    border: none;
}

.rpv-core__page-layer--single {
    border: none;
}

[aria-describedby="rpv-core__tooltip-body-get-file"] {
    display: none;
}

.rpv-core__inner-pages .rpv-core__inner-pages--vertical {
    height: 600px;
    overflow: hidden;
}