:root {
    /* Colour */
    --zanista-grey-light: #e0e0e0;
    --zanista-grey-mid: #afa8a8;
    --zanista-yellow-light: #fcefc9;
    --zanista-yellow-grey: #f1e6c6;
    --zanista-orange-light: #ffd8c5;
    --zanista-orange-mid: #ffbb9b;
    --zanista-orange: #ff915e;
    --zanista-orange-dark: #ff8952;
    --zanista-orange-darker: #d45e27;
    --zanista-red-light: #f5a6a6;
    --zanista-red-mid: #e97575;
    --zanista-red: #eb5151;
}