.coming-soon-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60vh;
}

.coming-soon-screen img {
    width: 150px;
    margin-bottom: 15px;
}

.coming-soon-screen p {
    text-align: center;
    font-size: medium;
    margin-top: 20px;
}

.coming-soon-screen .row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 20px;
}

.coming-soon-button {
    text-decoration: none;
    color: #000;
    border-radius: 5px;
    border: 2px solid #000;
    padding: 10px;
    background-color: var(--zanista-orange-mid);
    width: 180px;
    text-align: center;
}

@media screen and (min-width: 768px) {

    .coming-soon-screen p {
        font-size: large;
    }

    .coming-soon-screen .row {
        flex-direction: row;
    }
    
}