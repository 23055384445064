body {
    --keyboard-background: #f3f4f6;
    --keyboard-accent-color: #007BFF;
    --keyboard-toolbar-text: #000;
    --keyboard-toolbar-text-active: black;
    --keyboard-toolbar-background: #f5f5f5;
    --keyboard-toolbar-background-hover: #ff0000;
    --keyboard-toolbar-background-selected: #b6becf81;

    --keycap-background: #dbe0e981;
    --keycap-background-hover: #69799581;
    --keycap-background-active: #15233a81;
    --keycap-background-pressed: #32456481;

    --keycap-text: #000;
    --keycap-text-active: #ffffff;
    --keycap-text-hover: #444;
    --keycap-text-pressed: #ffffff;

    --keycap-shift-text: #007BFF;
    --keycap-shift-color: #014c9d;

    --keycap-secondary-background: #c6cedc81;
    --keycap-secondary-background-hover: #69799581;
    --keycap-secondary-text: #000;

    --box-placeholder-color: white;

    --variant-panel-background: #f0f0f0;
    --variant-keycap-text: #000;
    --variant-keycap-text-active: #333;
    --variant-keycap-background-active: #d0d0d0;
}