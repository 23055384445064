.exam-tabs {
    margin-bottom: var(--margin-x-large);
}

.exam-tabs .nav-button {
    background-color: var(--zanista-yellow-light);
    width: 15%;
    margin: 0px 5px;
    display: flex;
    justify-content: center;
    align-content: center;
}

.exam-tabs .nav-left {
    margin-left: 0px;
}

.exam-tabs .nav-right {
    margin-right: 0px;
}

@media only screen and (min-width: 768px) {
    .exam-tabs .tab-button {
        width: 22%;
    }

    .exam-tabs .complete,
    .exam-tabs .next,
    .exam-tabs .incomplete {
        display: inline;
    }

    .exam-tabs .nav-button {
        display: none;
    }
}