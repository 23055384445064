/* Hide the scrollbar */
::-webkit-scrollbar {
    width: 8px;
    /* You can adjust the width */
}

::-webkit-scrollbar-thumb {
    background-color: rgba(215, 215, 215, 0.5);
    /* Initially hidden */
    border-radius: 4px;
    transition: background-color 0.5s ease;
}

/* Show the scrollbar when scrolling */
div:hover::-webkit-scrollbar-thumb {
    background-color: rgba(215, 215, 215, 0.5);
    /* Visible during hover */
}