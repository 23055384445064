.header-container {
    width: 100%;
    max-width: 1200px;
}

.zanista-header img {
    height: 40px;
}

.zanista-header #username {
    display: inline;
}

.zanista-header * {
    text-decoration: none;
}

.zanista-header li {
    padding-left: 0px;
    display: inline-flex;
}

@media screen and (min-width: 768px) {
    .zanista-header li {
        padding-left: 5px;
    }
}

.zanista-header .home-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.zanista-header {
    background-color: white;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0 20px rgb(124, 124, 124);
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}


.zanista-header #username {
    display: none;
}

/* .user-info-modal {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding: 20px;
    background-color: white;
    border-radius: 5px;
    margin: auto;
    box-shadow: 0 0 20px rgb(124, 124, 124);
}

.user-info-modal p {
    text-align: start;
    margin-bottom: 5px;
} */

/* .user-info-modal button {
    background-color: var(--zanista-orange-mid);
    border: 1px solid;
    border-radius: 10px;
    padding: 8px 15px;
    margin-top: 10px;
} */

/* .user-info-title {
    display: flex;
    justify-content: space-between;
    align-items: end;
    width: 100%;
    margin-bottom: 5px;
}

.user-info-title p {
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 5px;
}

.user-info-title a {
    color: black;
    text-decoration: none;
    padding-bottom: 0;
    margin-bottom: 0;
} */

[data-popup='tooltip'].popup-overlay {
    background: transparent;
}

@media only screen and (min-width: 768px) {
    .zanista-header #username {
        display: inline;
    }
}