.divider {
    width: 100%;
    height: 20px;
    overflow: hidden;
}

.divider hr {
    /* Awful hacks to get a shadow under the hr */
    border: none;
    height: 50px;
    box-shadow: 0 0px 20px;
    margin: -50px auto 30px;
}

@media only screen and (min-width: 768px) {

    .exam-details .divider {
        display: block;
    }

}